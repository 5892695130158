@import url('https://fonts.googleapis.com/css2?family=Baloo+2&family=Cambay&display=swap');

/* GAP flex */

body {
  background:#fffcf6;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Cambay', sans-serif;
}

.overall-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

header {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 5vh;
  margin: 3vh 0 2vh 0;
}

.navbar-overall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.navbar-overall hr {
  width: 75vw;
  background-color: black;
}

.navbar-logo {
  width: 25%;
}

.navbar-container {
  background-color: transparent;
  align-self: center;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  color: #fffcf6;
}

.navbar-container li {
  margin: 15px 20px 10px 20px;
  list-style-type: none;
}
.navbar-container li:hover {
  transform: scale(1.3);
}

.navbar-container a {
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
  color: black;
}

.navbar-container a:hover {
  color: rgb(24, 77, 252);
}

.navbar-blank {
  width: 25%;
}

.home-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
}

.initial-view{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;
  width: 80%;
}

.about-me-link{
  text-align: center;
  font-size: larger;
  margin-bottom: 5vh;
}
.about-me-link a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  text-align: center;
  font-size: 1.1em;
}

.about-me-link a:hover {
  color: rgb(24, 77, 252);
  transform: scale(1.3);
}

.info-container{
  display: flex;
  gap: 1vw;
  justify-content: space-between;
  align-items: center;
}

.short-intro{
  text-align: center;
  width: 100%;
}

.portrait-container img {
  width: 300px;
  height: auto;
  border-radius: 1000px;
  border: 1px solid #000000;
  align-self: left;
}

.bio-container {
  display: flex;
  flex-direction: column;
  background-color: #fffcf6;
  border-radius: 5px;
  justify-content: center;
  align-self: center;
  margin: 0 2vw 0 2vw;
  padding: 3vh 0 3vh 0;
  width: 80vw;
  height: 85vh;
}

.bio-container p {
  color: black;
  margin: 2vh;
}

.resume-container {
  font-family: "Times New Roman", Times, serif;
  align-self: center;
  border: 2px solid #000000;
  border-radius: 5px;
  width: 70%;
  margin: 2%;
  text-align: center;
  color: black;
  background-color: rgba(255, 255, 255, 0.884);
}

.resume-container h2{
  text-align: left;
  width: 50%;
}
.resume-container p{
  margin: 0;
}
.resume-infobox{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.date{
  display: flex;
  margin: 5px 0 0 0;
  width: 40%;
  align-self: flex-start;
}
.description{
  display: flex;
  flex-direction: column;
  justify-self: left;
  margin: 5px 0 0 0;
  width: 60%;
}

.resume-education p,
.resume-experience p,
.resume-skills p{
  text-align: left;
  font-size: 0.875em;
}
.resume-education b,
.resume-experience b,
.resume-skills b{
  text-align: left;
}
.resume-education,
.resume-experience,
.resume-skills{
  margin: 20px;
}

h1 {
  margin: 0;
}

.contact-content {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  text-align: left;
  align-items: center;
  justify-content: center;
}

.contact-icon {
  color: black;
  font-size: 35px;
  margin: 0 10px 0 10px;
}

.contact-icon:hover {
  transform: scale(1.3);
  color:rgb(24, 77, 252);
}

.portfolio-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  width: 90vw;
}

.portfolio-container h1 {
  margin: 20px 0 0 0;
  justify-self: center;
}

.projects-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
  margin: 2%;
}

.projects-container a {
  margin: 2vw;
}

.projects-container img{
  height: 150px;
}

.projects-container div:hover {
  transform: scale(1.02);
  background-color:rgb(255, 227, 190);
  transition-duration: 0.5s;
}

.project-box{
  display: flex;
  flex-direction: column;
  background-color: white;
  border-style: solid;
  border-radius: 5px;
  width: 30vw;
  height: 100%;
  margin: 1%;
  padding: 1%;
}

.head{
  text-align: center;
  margin: 10px;
  color: black;
  align-self: center;
}

.text{
  width: 80%;
  margin: 10px 10px 10px 20px;
  color: black;
}

.drone {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}
.drone p {
  width: 85vw;
  align-self: center;
  text-align: left;
}

.drone a,
.project-site-container a {
  width: 15vw;
  text-decoration: none;
  color: black;
  font-weight: bold;
  text-align: center;
  margin: 1vh 0 0 5vw;
  font-size: 1.3em;
}

.drone a:hover,
.project-site-container a:hover {
  margin-left: 5vw;
  transform: scale(1.1);
}

.pictures {
  display: flex;
  width: 95vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.pictures img {
  width: 20vw;
  margin: 1vw;
}

.pictures img:hover {
  transform: scale(1.1);
}

.project-site-container {
  display: flex;
  flex-direction: column;
  min-height: 92vh;
}

.project-site-header {
  display: flex;
  align-self: center;
  justify-self: flex-start;
}

.project-site-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85vw;
  align-self: center;
}

.project-site-intro img {
  width: 50vw;
}

.project-site-content {
  width: 90vw;
}

.project-site-content a {
  margin: 0;
  
}

.project-site-content a:hover {
  margin: 0;
}

.project-site-content hr {
background-color: black;
margin: 5vh 0 5vh 0;
}

.project-site-strengths,
.project-site-result,
.project-site-reflection {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.project-site-purpose,
.project-site-my-contribution,
 .project-site-improvements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-site-improvements {
  margin-bottom: 5vh;
}

.project-site-text {
  width: 100%;
  margin-right: 5vw;
}

.project-site-text-reverse {
  width: 100%;
  margin-left: 5vw;
}

.project-site-purpose img,
.project-site-strengths img,
.project-site-my-contribution img,
.project-site-result img {
  width: 50vw;
}

.project-site-improvements img,
.project-site-reflection img {
  height: 40vh;
}

.scroll-to-top-link {
  display: flex;
  justify-content: center;
}

#link-to-project {
  width: 15vw;
  text-decoration: underline;
  color: blue;
  font-weight: normal;
  text-align: center;
  margin: 0 0 1vh 0;
  font-size: 1.1em;
}

.scroll-to-top-box {
  display: flex;
  flex-direction: column;
  color: black;
  margin-bottom: 1vh;
}

.scroll-to-top-box p {
  margin: 0;
}

footer {
  display: flex;
  width: 100%;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8vh;
  width: 100%;
  background-color: black;
  color: white;
}

@media only screen and (max-width: 600px) {

  .info-container {
    flex-direction: column-reverse;
  }

  #link-to-project {
    width: 85vw;
  }

  .projects-container {
    flex-direction: column;
  }

  .project-site-text {
    margin: 0 1vw 0 1vw;
  }

  .project-site-text-reverse {
    margin: 0 1vw 0 1vw;
  }

  .project-box {
    width: 90vw;
  }

  .navbar-logo h1{
    font-size: 0.9em;
  }
  .navbar-container{
    font-size: 0.8em;
  }
  .home-container{
    flex-direction: column;
  }
  .portrait-container img {
    width: 200px;
  }
  .bio-container{
    align-self: center;
    width: 100%;
    height: 100%;
  }
  .resume-container{
    width: 100%;
    margin: 30px 0 0 0;
  }
  .date,
  .description{
    margin: 2px;
  }
  .project-box,
  .pictures {
    flex-direction: column;
    align-items: center;
  }
  .pictures img {
    width: 90vw;
  }

  .pictures img:hover {
    transform: none;
  }

  #fullscreen-picture-text {
    display: none;
  }

  .project-site-purpose,
  .project-site-strengths,
  .project-site-my-contribution,
  .project-site-result,
  .project-site-improvements,
  .project-site-reflection {
    flex-direction: column;
    align-items: center;
  }

  .project-site-purpose img,
  .project-site-strengths img,
  .project-site-my-contribution img,
  .project-site-intro img,
  .project-site-result img {
    width: 100%;
  }

  .project-site-reflection img,
  .project-site-improvements img {
    height: 25vh;
  }

  .project-site-container a,
  .drone a {
    font-size: 1em;
  }

  .drone p {
    width: 90vw;
  }

  .scroll-to-top-link a {
    width: 100%;
  }

}

@media only screen and (min-width: 1200px) {
  .resume-container {
    width: 50%;
  }
  .projects-container{
    width: 70vw;
  }

}